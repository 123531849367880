import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"


// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import MyReferral from "pages/MyReferral"
import checkPermission from "functions/checkPermission"
import {border} from "@mui/system";


const SidebarContent = props => {
  const ref = useRef();

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously

  var appState = JSON.parse(localStorage.getItem('applicationState')); 
  const appReducer = appState && appState.appReducer && appState.appReducer;
  const userDetails = appState && appState.appReducer && appState.appReducer.userDetails;
  const appRole = userDetails && userDetails.appRole;

  const sidebaritem = [
                {title:"Dashboard",linkto:"/dashboard", code:"DASHBOARD",icon:"bx bx-home-circle"},
                {title:"Create a Referral",linkto:"/create-referral",icon:"bx bx-paper-plane"},
                {title:userDetails?.accountType == "REFERRING" ? "Referrals":"My Referral",linkto:"/my-referral",icon:"bx bx-list-ol"},
                {title:"About",linkto:"/about",icon:"bx bx-info-circle"},
                {title:"FAQs",linkto:"/faqs",icon:"bx bx-help-circle"},
                {title:"T&Cs",linkto:"/terms",icon:"bx bx-shield-quarter"},
                {title:"Profile", linkto: "/profile",icon:"bx bx-user"},
                // {title:"Feedback", linkto: "/feedback",icon:"bx bx-calendar-star"},
                
          ];
          console.log(sidebaritem)

  if(appRole != "REFERRING_CLINICIAN"){
    sidebaritem.splice(1,1)
  }
  if(checkPermission('user-management')){
    sidebaritem.push({title:"User Management", linkto: "/User-Management",icon:"bx bx-user-check"},)
  }


  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      console.log("sidebar items = ",items)
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>


          {sidebaritem.map((item,key)=>(
              <li key = {key}>
              <Link to={item.linkto} className="">
                <i className={item.icon}></i>
                <span>{props.t(item.title)}</span>
                
              </Link>
            </li>
            ))
            }
            {checkPermission('email-admin') && (
              <li className="position-absolute bottom-0">
              <Link to='/#' className="has-arrow">
                <i className='bx bx-cog'></i>
                <span>Settings</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className="menu-title">Email Template</li>
                <li>
                  <Link to="/email-template/create"> <i className='bx bx-mail-send'></i>Create template</Link>
                </li>
                <li>
                  <Link to="/email-templates"> <i className='bx bx-list-ul'></i>List template</Link>
                </li>
              </ul>
            </li>
            )}
          </ul>
        </div>
        
      </SimpleBar>
     
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))




{/*
 <li>
              <Link to="/dashboard" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li>
              <Link to="/create-referral" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Create Referral")}</span>
              </Link>
            </li>
            <li>
              <Link to="/my-referral" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("My Referral")}</span>
              </Link>
            </li>
            <li>
              <Link to="/referral-detail" className="">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Referral Detail")}</span>
              </Link>
            </li>




*/}