/* App config for apis
 */
import dotenv from 'dotenv'

const apiConstants = {
    BASE_URL: process.env.REACT_APP_API_URL, //"https://trvapi-test.herokuapp.com/api/" , // "http://localhost:3000" ,//'process.env.REACT_APP_API_URL', //'https://troms-api-dev.herokuapp.com/api/', //https://troms-api-dev.herokuapp.com //https://troms-api-test.herokuapp.com
    BASE_EMAIL_URL: process.env.REACT_APP_EMAIL_API_URL, //not needed now - just dev purposes
    LOGIN: 'user/login',
    CREATEUSER: 'user/create',
    REGISTER: 'user/register',
    EXTEND_TOKEN: 'user/extendToken',
    UPDATE_USER: 'user/update',
    CREATE_PATIENT: 'patient/create',
    GET_HOSPITALS: 'hospitals',
    FORGOT_PASSWORD: 'user/forgot',
    SUBSCRIPTION:'subscription/active-list',
    FAQ_LIST:'faqs/list',
    CHANGE_PASSWORD: 'user/changePassword',
    SOFT_ISSUE_PATHWAY: 'pathways/softTissue',
    INJURYSUMMARY: 'cases/submit',
    INJURYSUMMARYA: 'streams/sendLeaflet',
    INJURYSUMMARYB: 'referrals/create',
    INJURYSUMMARYLeaflts: 'streams/availableLeaflets',
    IMAGESURL: 'images/requestSignedURL',
    DELETE_IMAGES: '/images/deleteImages',
    DELETE_OPHTH_IMAGES: '/images/deleteImagesOphth',
    GET_RHEU_IMAGE_URLS:'/images/rheumatology/requestSignedURL',
    MID_RHEU_IMAGE_URLS:'/images/rheumatology/',
    PATHWAY: 'pathways/',
    CONFIG: 'config',
    GETMESSAGE:'cases/getMessage?caseID=',
    SEND_MESSAGE : 'cases/sendMessage',
    DASHBOARDINFO: 'cases/v2/dashboardInfoRev',
    ANEDASHBOARDINFO: 'cases/v2/dashboardInfoRef',
    DASHBOARDDETAILS: 'cases/getManyWeb',
    REFERRALDDETAILS: 'cases/',
    CREATE_CASE: 'cases/create',
    UPDATECASE: 'cases/updateOne',
    UPDATE_CASE_STATUS: 'cases/updateOneStatus',
    UPDATE_CASE_CONSENT: 'cases/consent',
    BIRTH_RELATED: 'referral/birthRelated',
    AVAILABLE_SPECIALITIES: 'pathways/availableSpecialties',
    PATHWAY_FINDER: 'pathways/pathwayFinder?specialty=',
    CASE_CONTACTED: 'cases/contacted',
    SAVE_CONTACTED_DATA: 'cases/saveContactedData',
    FEEDBACK: 'feedback/submit',
    FEEDBACK_BWC: 'feedback/submitbwc',
    GETFEEDBACK:'feedback/getFeedback',
    DECISION_CONTENT_API: 'endpoint/get',
    GET_ENDPOINT:'endpoint/getv2',
    OTMG_REFERRALS: 'referrals/ophthalmology',
    SET_ON_CALL: 'streams/setOnCall',
    GET_ON_CALL: 'streams/getOnCall',
    SET_ON_CALL_FROM_LIST:'streams/setOnCallFromList',
    ORG_SEARCH_VAL:'config/search-organisation?q=',
    UPLOAD_VISUAL: 'cases/visualAcuity',
    GET_DRUGS: 'pathways/getDrugs',
    GET_Patient_Data:'PDS/',
    GET_PDS_SEARCH: 'PDS/search',
    OPTHO_UPLOAD_IMAGE: 'images/ophthalmologyRequestSignedURL',
    CHECK_DRUG: 'pathways/drugLookup?name=',
    MID_RESPONSE_SAVE: 'cases/saveMidResponse',
    GET_CLINICIAN_LIST: 'streams/getClinicianList',
    REASSIGN_CASE: 'cases/reassign',
    RUBBER_STAMP: 'cases/rubberStamp',
    GET_RUBBER_STAMP: 'actions/get',
    GET_UNAUTHORISED_LIST: 'user/management/authoriseList',
    AUTHORISE_USER: 'user/management/authorise',
    REJECT_USER:'user/management/reject',
    DISABLE_USER: 'user/management/disable',
    VERIFY_USER_2FA: 'user/otp/verify',
    REQUEST_QR_CODE_WITH_AUTH: 'user/otp/requestqr',
    SETUP_2FA: 'user/otp/setup',
    DESTROY_TOKEN: 'user/destroyToken',
    GET_REAU_SCREENS:'/cases/rheumatology/sendScreen',
    REAU_CALC:'/cases/rheumatology/calculate',
    REQUEST_2FA_BACKUP: 'user/otp/email',
    REQUEST_AUTHCODE: 'user/authCode',
    REQUEST_CASE_PDF: '/cases/endpointPDF',
    INSTITUTIONAL_DASHBOARD:'cases/institutionalDashboard',
    CANCEL_CASE:"cases/setCancelled",
    SUBMIT_EMAIL:"emails/add",
    UPDATE_EMAIL:"emails/update",
    GET_EMAIL_LIST:"emails/getMany",
    EMAIL_TRIGGERS:"emails/triggers",
    EMAIL_EVENTS:"emails/events",
    GET_ONE_EMAILTEMPLATE:"emails/getOne",
    DELETE_EMAILTEMPLATE:"emails/delete",
    ACCEPT_NEW_OWNER: "cases/tempOwnerAcceptance",
    FIND_LOCAL_HOSPITAL: "pathways/findLocalHospital",
    GET_AUDIT_FAILS: "audits/getAuditFails",
    GET_AUDIT_FAILS_COUNT: "audits/countAuditFails",
    UPDATE_AUDIT: "audits/updateHistory",
    UPLOAD_PDF: "cases/uploadPDF",
    CLOSE_QUERY: "cases/closeMessage",
    GET_PDF: "PDF/getPDFLink"

};

export default apiConstants;
