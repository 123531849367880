import { Button } from "react-bootstrap"
import React, { useCallback, useEffect, useRef, useState } from "react"
import Webcam from "react-webcam"
import axios from "axios"
import { showToast } from "utils/toastnotify"
import { uploadImage } from "servicies/UserServicies"
import moment from "moment"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { Card } from "reactstrap"
import apiConstants from "api/apiConstants"
import triviceLogo from "../../assets/images/TriVice_logo_with_text.png"
import errorImg from "../../assets/images/image-upload-url.png"

const FACING_MODE_USER = "user"
const FACING_MODE_ENVIRONMENT = "environment"
const videoConstraints = {
  facingMode: FACING_MODE_USER,
}

const ImageUploadLink = () => {
  const webcamRef = useRef(null)
  const [img, setImg] = useState("")
  const [error, setError] = useState("")
  const [numberOfFiles, setNumberOfFiles] = useState(0)
  const [decodedData, setDecodedData] = useState("")
  const [eyeSide, setEyeSide] = useState("")
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const handleClick = React.useCallback(() => {
    setFacingMode(prevState =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    )
  }, [])

  const capture = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot()
    setImg(imageSrc)
  }, [])

  useEffect(() => {
    try {
      const currentUrl = window.location.search
      const urlParams = new URLSearchParams(currentUrl)
      if (urlParams?.size == 0) {
        setError("Invalid URL")
        return
      }
      const encodedData = urlParams.get("data")
      // console.log("encoded data >>>", encodedData)
      const data = decodeURIComponent(encodedData)
      let obj = JSON.parse(data)
      if (obj?.token == undefined || obj?.caseID == undefined) {
        setError("Invalid URL")
        return
      }
      if (moment().isAfter(moment(obj?.expires))) {
        // showToast("Link is expired", "error")
        setError("URL has expired")
        return
      }
      if (obj?.leftEye) {
        setEyeSide("Left")
      }
      if (obj?.rightEye) {
        setEyeSide("Right")
      }
      setDecodedData(obj)
    } catch (error) {
      setError("Invalid URL")
    }
  }, [])

  // console.log("img", img)

  const uploadOpthoImage = () => {
    const [metadata, base64Data] = img.split(",")
    const d = atob(base64Data)

    const uint8Array = new Uint8Array(d.length)

    for (let i = 0; i < d.length; i++) {
      uint8Array[i] = d.charCodeAt(i)
    }
    const blob = new Blob([uint8Array], { type: "image/jpeg" })
    let payload = {
      leftEye: [],
      rightEye: [],
    }

    if (eyeSide == "Left") {
      payload.leftEye = ["Other"]
    } else {
      payload.rightEye = ["Other"]
    }

    let url = `${process.env.REACT_APP_API_URL}${apiConstants.OPTHO_UPLOAD_IMAGE}?caseID=${decodedData?.caseID}`

    axios
      .post(url, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + decodedData?.token,
        },
      })
      .then(opthoimageUrlsResponse => {
        // returns
        // {
        //  leftEye: ['url1','url2']
        //  rightEye: ['url3','url4']
        // }
        if (opthoimageUrlsResponse?.status != 200) {
          setLoading(false)
          window.alert("Image has failed to upload, please try again.")
          return
          // return showToast("Image has failed to upload, please try again.", "error")
        }

        try {
          if (opthoimageUrlsResponse.data?.leftEye?.length > 0) {
            const opthoimageVideoUrlsL =
              opthoimageUrlsResponse.data?.leftEye?.map(async item => {
                await uploadImage(item.url, blob, "image/jpeg")
              })
            Promise.all(opthoimageVideoUrlsL)
              .then(results => {
                setLoading(false)
                showToast("Image upload successfully", "success")
                window.location.replace("https://trivice.net/thank-you/")
              })
              .catch(error => {
                setLoading(false)
                showToast("Failed to upload image", "error")
              })
          } else if (opthoimageUrlsResponse.data?.rightEye?.length > 0) {
            const opthoimageVideoUrlsR =
              opthoimageUrlsResponse.data?.rightEye?.map(async item => {
                await uploadImage(item.url, blob, "image/jpeg")
              })
            Promise.all(opthoimageVideoUrlsR)
              .then(results => {
                setLoading(false)
                showToast("Image upload successfully", "success")
                window.location.replace("https://trivice.net/thank-you/")
              })
              .catch(error => {
                setLoading(false)
                window.alert("Image has failed to upload, please try again.")
                // showToast("Failed to upload image", "error")
              })
          }
        } catch (error) {
          console.log("ERROR", { error })
        }
      })
      .catch(error => {
        console.log("error")
        setLoading(false)
        // showToast("Image has failed to upload, please try again.", "error")
        window.alert("Image has failed to upload, please try again.")
      })
  }

  const upload = () => {
    if (
      moment().isAfter(
        moment(decodedData?.expires).format("YYYY-MM-DD HH:mm:ss")
      )
    ) {
      // showToast("Link is expired", "error")
      setError("URL has expired")
      return
    }
    if (img == "") {
      showToast("Please capture image first", "error")
      return
    }
    if (decodedData?.caseID == undefined) {
      showToast("Can't upload image", "error")
      return
    }
    setLoading(true)

    if (eyeSide != "") {
      return uploadOpthoImage()
    }

    const [metadata, base64Data] = img.split(",")
    const d = atob(base64Data)

    const uint8Array = new Uint8Array(d.length)

    for (let i = 0; i < d.length; i++) {
      uint8Array[i] = d.charCodeAt(i)
    }
    const blob = new Blob([uint8Array], { type: "image/jpeg" })

    let url = `${process.env.REACT_APP_API_URL}${
      apiConstants.IMAGESURL
    }?caseID=${decodedData?.caseID}&numFiles=${numberOfFiles + 1}`
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + decodedData?.token,
        },
      })
      .then(imageUrlsResponse => {
        if (imageUrlsResponse?.status === 200) {
          const imageVideoUrls = imageUrlsResponse.data.urls.map(
            async (imageVideoUrl, index) => {
              await uploadImage(imageVideoUrl, blob, "image/jpeg")
            }
          )

          try {
            Promise.all(imageVideoUrls).then(() => {
              setNumberOfFiles(numberOfFiles + 1)
              setLoading(false)
              showToast("Image has been successfully uploaded", "success")
              window.location.replace("https://trivice.net/thank-you/")
            })
          } catch (error) {
            setLoading(false)
            console.log({ error })
          }
        } else {
        }
      })
      .catch(error => {
        setLoading(false)
        showToast("Image has failed to upload, please try again.", "error")
        window.alert("Image has failed to upload, please try again.")
      })
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        padding: "20px",
      }}
    >
      <img
        src={triviceLogo}
        width="150"
        alt="logo"
        style={{ alignSelf: "flex-start" }}
      />

      {error != "" ? (
        <>
          <img src={errorImg} alt="invalid_url" width="70%" />
          <h5 className="mt-3">{error}</h5>
          <h6 className="text-center">
            Please close the tab and scan the QR code again
          </h6>
        </>
      ) : (
        <>
          <div style={{ width: "80vw" }}>
            <Card className="mt-3 text-center">
              <h5 className="p-2">
                {eyeSide != ""
                  ? `Capture an image of ${eyeSide} Eye to upload to your on-going TriVice referral`
                  : "Capture an image to upload to your on-going TriVice referral"}
              </h5>
            </Card>
          </div>

          <div className="mb-4">
            <Card className="p-2" style={{ width: "80vw" }}>
              {img != "" ? (
                <img src={img} className="mt-4 mb-2" />
              ) : (
                <Webcam
                  audio={false}
                  className="mt-1 mb-2"
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    ...videoConstraints,
                    facingMode,
                  }}
                  ref={webcamRef}
                />
              )}
              <div className="text-center">
                <Button
                  variant="secondary"
                  onClick={() => handleClick()}
                  style={{
                    padding: "1px 7px",
                    fontSize: "22px",
                  }}
                >
                  <i className="mdi mdi-camera-flip-outline" />
                </Button>
                <Button
                  className="ms-3"
                  style={{
                    padding: "1px 7px",
                    fontSize: "22px",
                  }}
                  onClick={capture}
                >
                  <i className="mdi mdi-camera-plus-outline" />
                </Button>
                <Button
                  variant="danger"
                  className="ms-3"
                  onClick={() => setImg("")}
                  style={{
                    padding: "1px 7px",
                    fontSize: "22px",
                  }}
                >
                  <i className="mdi mdi-camera-retake-outline" />
                </Button>
                <Button
                  variant="success"
                  onClick={upload}
                  disabled={loading}
                  className="ms-3"
                  style={{
                    padding: "1px 7px",
                    fontSize: "22px",
                  }}
                >
                  {loading ? (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
                  ) : (
                    <i className="mdi mdi-cloud-upload-outline" />
                  )}
                </Button>
              </div>
            </Card>

            {/* <Button
            variant="danger"
            onClick={() => window.close()}
            className="ms-3"
            style={{
              padding: "1px 7px",
              fontSize: "22px",
            }}
          >
            <i className="bx bx-camera-off " />
          </Button> */}
          </div>
        </>
      )}
    </div>
  )
}

export default ImageUploadLink