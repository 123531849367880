import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Row, Col, Collapse, Table, Card, CardBody, CardDeck } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { createReferralCreators } from "store/create-referral/reducer"
import CdBasicInfo from "./CdBasicInfo"
import GenerateCaseDetails from "components/GeneratecaseDetails"
import styled from "styled-components"

const ContactsAndGDPR = props => {

  return (
    <div style={{ backgroundColor: "#F8F8FB" }}>
      <Row>
        <Col xl="4" md="6">
          <Card style={{ height: "95%", margin: "-3px" }}>
            <CardBody>
              <Table className="table-striped">
                <thead>
                  <tr>
                    <th colspan="2" class="border-bottom-0 bg-white">
                      <h4>Patient Details</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="col-lg-3 col-md-6">
                      <b>Name</b>
                    </td>
                    <td class="col-lg-9 col-md-6">
                      {props.Case?.firstName + " " + props.Case?.lastName}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6 bg-white">
                      <b>Email</b>
                    </td>
                    <td class="col-lg-9 col-md-6 bg-white"
                    style={{ wordWrap: "break-word", wordBreak: "break-all"}}>
                      {props.Case?.patientEmailAddress ? props.Case?.patientEmailAddress : "Not Provided"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6">
                      <b>Phone</b>
                    </td>
                    <td class="col-lg-9 col-md-6">
                      {props.Case?.patientPhoneNumber ? props.Case?.patientPhoneNumber : "Not Provided"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6 bg-white border-bottom-0">
                      <b>Address</b>
                    </td>
                    <td class="col-lg-9 col-md-6 bg-white border-bottom-0">
                      {props.Case?.patientAddress ? props.Case?.patientAddress + ", " + (props.Case?.postCode ? props.Case?.postCode.toUpperCase() : "") : "Not Provided"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>

        <Col xl="4" md="6">
          <Card style={{ height: "95%", margin: "-3px" }}>
            <CardBody>
              <Table className="table-striped">
                <thead>
                  <tr>
                    <th colspan="2" class="border-bottom-0 bg-white">
                      <h4>Parent/Carer Details</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="col-lg-3 col-md-6">
                      <b>Name</b>
                    </td>
                    <td class="col-lg-9 col-md-6">
                      {props.Case?.parent?.firstName || props.Case?.parent?.lastName
                        ? props.Case?.parent?.firstName + " " + props.Case?.parent?.lastName
                        : "Not provided"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6 bg-white">
                      <b>Email</b>
                    </td>
                    <td
                      class="col-lg-9 col-md-6 bg-white"
                      style={{ wordWrap: "break-word", wordBreak: "break-all"}}
                    >
                      {props.Case?.parent?.emailAddress ? props.Case?.parent?.emailAddress : "Not Provided"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6">
                      <b>Phone</b>
                    </td>
                    <td class="col-lg-9 col-md-6">
                      {props.Case?.parent?.contactNumber ? props.Case?.parent?.contactNumber : "Not Provided"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6 bg-white border-bottom-0">
                      <b>Address</b>
                    </td>
                    <td class="col-lg-9 col-md-6 bg-white border-bottom-0">
                      Not Provided
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>

        <Col xl="4" md="6">
          <Card style={{ height: "95%", margin: "-3px" }}>
            <CardBody>
              <Table className="table-stripped">
                <thead>
                  <tr>
                    <th colspan="2" class="border-bottom-0 bg-white">
                      <h4>Consents</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="col-lg-10 col-md-10 border-left">
                      <b>Assessment, Treatment and Referral</b>
                    </td>
                    <td class="col-lg-2 col-md-2">
                      {props.Case?.consent?.treatment ? "Yes" : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-10 col-md-10 bg-white">
                      <b>Medical Teaching and Research</b>
                    </td>
                    <td class="col-lg-2 col-md-2 bg-white">
                      {props.Case?.consent?.research ? "Yes" : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-10 col-md-10">
                      <b>Refused to share images </b>
                    </td>
                    <td class="col-lg-2 col-md-2">
                      {props.Case?.consent?.treatment || props.Case?.consent?.research ? "No" : "Yes"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col bg-white border-bottom-0"></td>
                    <td class="col bg-white border-bottom-0"></td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>

        <Col xl="4" md="6">
          <Card style={{ height: "95%", margin: "-3px" }}>
            <CardBody>
              <Table className="table-striped">
                <thead>
                  <tr>
                    <th colspan="2" class="border-bottom-0 bg-white">
                      <h4>Referrer Details</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="col-lg-3 col-md-6">
                      <b>Name</b>
                    </td>
                    <td class="col-lg-9 col-md-6">
                    {props.Case?.assignedReferrer?.firstName || props.Case?.assignedReferrer?.lastName
                    ? props.Case?.assignedReferrer?.firstName + " " + props.Case?.assignedReferrer?.lastName
                    : "Not provided"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6 bg-white">
                      <b>Email</b>
                    </td>
                    <td
                      class="col-lg-9 col-md-6 bg-white"
                      style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                    >
                      {props.Case?.assignedReferrer?.email ? props.Case?.assignedReferrer?.email : "Not Provided"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6">
                      <b>Phone</b>
                    </td>
                    <td class="col-lg-9 col-md-6">
                      {props.Case?.assignedReferrer?.phoneNumber ? props.Case?.assignedReferrer?.phoneNumber : "Not Provided"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6 bg-white border-bottom-0">
                      <b>Organisation</b>
                    </td>
                    <td class="col-lg-9 col-md-6 bg-white border-bottom-0">
                      {props.Case?.createdBy?.organisation?.[0]?.name ? props.Case?.createdBy?.organisation?.[0]?.name : "Not Provided"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>

        <Col xl="4" md="6">
          <Card style={{ height: "95%", margin: "-3px" }}>
            <CardBody>
              <Table className="table-striped">
                <thead>
                  <tr>
                    <th colspan="2" class="border-bottom-0 bg-white">
                      <h4>Duty Consultant Details</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="col-lg-3 col-md-6">
                      <b>Name</b>
                    </td>
                    <td class="col-lg-9 col-md-6">
                      {props.Case?.dutyConsultantName ? props.Case?.dutyConsultantName : "Not Provided"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6 bg-white">
                      <b>Email</b>
                    </td>
                    <td class="col-lg-9 col-md-6 bg-white">Not Provided</td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6">
                      <b>Phone</b>
                    </td>
                    <td class="col-lg-9 col-md-6">
                      {props.Case?.dutyConsultantNumber ? props.Case?.dutyConsultantNumber : "Not Provided"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6 bg-white border-bottom-0">
                      <b>Organisation</b>
                    </td>
                    <td class="col-lg-9 col-md-6 bg-white border-bottom-0">
                      Not Provided
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>

        <Col xl="4" md="6">
          <Card style={{ height: "95%", margin: "-3px" }}>
            <CardBody>
              <Table className="table-striped">
                <thead>
                  <tr>
                    <th colspan="2" class="border-bottom-0 bg-white">
                      <h4>GP Details</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="col-lg-3 col-md-6">
                      <b>Name</b>
                    </td>
                    <td class="col-lg-9 col-md-6">
                      {props.Case?.gpFirstName ? props.Case?.gpFirstName : "Not Provided"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6 bg-white">
                      <b>Email</b>
                    </td>
                    <td
                      class="col-lg-9 col-md-6 bg-white"
                      style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                    >
                      {props.Case?.gpEmailAddress ? props.Case?.gpEmailAddress : "Not Provided"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6">
                      <b>Phone</b>
                    </td>
                    <td class="col-lg-9 col-md-6">
                      {props.Case?.gpPhoneNumber ? props.Case?.gpPhoneNumber : "Not Provided"}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-lg-3 col-md-6 bg-white border-bottom-0">
                      <b>Address</b>
                    </td>
                    <td class="col-lg-9 col-md-6 bg-white border-bottom-0">
                      {props.Case?.gpStoreAddress ? props.Case?.gpStoreAddress : "Not Provided"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

ContactsAndGDPR.propTypes = {
  Case: PropTypes.object.isRequired,
}

export default ContactsAndGDPR
