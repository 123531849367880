/* eslint-disable react/prop-types */
import moment from "moment"
import React, { useEffect, useState } from "react"
import QRCode from "react-qr-code"
import { useSelector } from "react-redux"

const ImageUploadQrCode = ({leftEye,rightEye}) => {
  const [ encodedData, setEncodedData] = useState("")
  const { token, caseDetails } = useSelector(state => ({
    token: state.appReducer.token,
    caseDetails: state.CreateReferral.caseDetails,
  }))

  useEffect(() => {
    let currentTime =  moment().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    let data = {
      token: token,
      caseID: caseDetails?.caseID,
      expires: currentTime,
    }
    if(leftEye) {
      data = {
        ...data,
        leftEye: true,
      }
    }
    if(rightEye) {
      data = {
        ...data,
        rightEye: true,
      }
    }

    const encode = encodeURIComponent(JSON.stringify(data));
    setEncodedData(encode)

  }, []);

  console.log("encodedData", encodedData)
  

  return (
      <QRCode
        style={{
          height: "100px",
          width: "100px",
          marginTop: '20px'
        }}
        value={`https://bwc.trivice.net/image-upload?data=${encodedData}`}
        viewBox={`0 0 100 100`}
      />
  )
}

export default ImageUploadQrCode
