import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Modal,
    ModalHeader,
    ModalBody,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux';
import { createReferralCreators } from 'store/create-referral/reducer';
import noImage from 'assets/images/NO-IMAGE-AVALAIBLE.png'
import img1 from 'assets/images/product/img-1.png'
import classnames from "classnames";

import caseDetails, {caseTypes,caseCreators } from "store/caseDeatils/reducer"
import ClipLoader from 'react-spinners/ClipLoader'

import DocViewer, {DocViewerRenderers, PDFRenderer} from '@cyntler/react-doc-viewer'


const Documents = (props) => {
  const [currentDoc,setcurrentDoc] = useState({src: noImage, type:'image/png'});
  const [activeTab, setActiveTab] = useState(0)
  const [currentIMGTitle, setCurrentIMGTitle]= useState("")
  const {loading} =useSelector( state=>({
    loading: state.caseDetails.downloading

  }))
    useEffect(()=>{
      if(props?.documents){
      setcurrentDoc(props?.documents[0])
      }
    },[props.documents])


    useEffect(()=>{
      if(loading) setcurrentDoc({src:noImage, type:'image/png'})
      if(!loading){
        try {
          null
            setcurrentDoc(props.documents[0])
          
        } catch (error) {
          setcurrentDoc({src:noImage, type:'image/png'})
        }
      }
    },[loading])

    

    // useEffect(async()=>{
    //   if(currentIMG.type == "application/dicom"){
    //     let file = await convertUrlToImageData(currentIMG.src)
    //     setDicom(file)
    //     console.log("CITUYBOYS",file)
    //   }
    // },[currentIMG])
    
    const toggleTab = (tab)=> {
        if (activeTab !== tab) {
          setActiveTab(tab)
        }
      }
    if (loading) return (
      <div className='text-center'>
        <span>
          <ClipLoader loading={loading} size={150}/>
        </span>
      </div>
      
      )
        return (
            <div className="product-detai-imgs" style={{height:'60vh'}} >
            <Row>
              <Col md="2" xs="3">
                <Nav className="flex-column" pills>
                
                  {
                        props && props.documents && props?.documents?.map((item,ind)=>
                       <>
                  
                          
                          <NavItem>
                          <NavLink 
                            className={classnames({
                              active: item == currentDoc,
                            })}
                            onClick={() => {
                              toggleTab(ind)
                              setcurrentDoc(item)
                            }}
                          >
                              <i className='bx bxs-file' />
                            
                          </NavLink>
                        </NavItem>
                    </>
                        )
                    
                  }
    
                </Nav>
              </Col>
              <Col md={{ size: 9, offset: 0 }} style={{height:'60vh'}} xs="9">

              
              
              <object
                data={props?.documents ? currentDoc.src : noImage}
                type={ props?.documents ? "application/pdf" : "image/png"}
                width="100%"
                height="100%"
              >

              <iframe width="100%"
                height="100%"
                    src={props?.documents ? currentDoc.src : noImage}
                    >
                    <p>This browser does not support PDF!</p>
              </iframe>
        
                    
              </object>
              
              
              </Col>
            </Row>
            
            </div>
        );

};
Documents.propTypes = {
    documents: PropTypes.array,
    speciality: PropTypes.string
}

export default Documents;


// <DocViewer style={{height:'100%'}} pluginRenderers={DocViewerRenderers} prefetchMethod='GET' documents={currentIMG} />
